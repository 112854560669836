


























































































import api from "@/api/index"; //ABP API接口
import {Vue, Component} from "vue-property-decorator";

import {
  ActivityDto, AuditFlowScope,
  CmsAnchorDto,
  CmsAnchorDtoPagedResultDto, DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  QuestionType
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "ActivityRecordList",
  components: {
    PagedTableView,
    ExportButton,
    AgileAuditTag
  },
})
export default class ActivityRecordList extends Vue {
  queryForm = {
    surName: "",
    activityTitle: "",
    volunteerCode: ""
  };

  typeList: DataDictionaryDto[] = [];

  // 获取表数据
  fetchData(params: any) {
    return api.activityRecord.getAll(params);
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ActivityRecord,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  // 详情
  handleDetail(index: number, row: any) {
    this.id = row.id!;
    this.$router.push({
      name: "ActivityRecordDetail",
      params: {id: row.id! + ""},
    });
  }
}
