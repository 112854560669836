












































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import ExportButton from "@/components/ExportButton/index.vue";
import {
  ActivityDto,
  ActivityApplyDto,
  ActivityApplyPagedResultRequestDto,
  ActivityApplyStatus,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import PagedTabsTableView from "@/components/PagedTableView/PagedTabsTableView.vue";

@Component({
  name: "ActivityApplyList",
  components: {
    PagedTabsTableView,
    PagedTableView,
    ExportButton,
  },
})
export default class ActivityApplyList extends Vue {
  @Ref() readonly pagedTableView!: PagedTableView;

  @Watch("queryForm.activityTitle")
  watchTitle(val: string, old: string) {
    console.log("val:" + val);
    console.log("old:" + old);
    this.queryForm.activityId = undefined;
  }

  detailId = 0;

  created() {
    if (this.$route.params.activityId) {
      this.activityId = Number(this.$route.params.id);
      this.queryForm.activityId = this.activityId;
      api.activity.get({ id: this.activityId }).then((res) => {
        this.detail = res;
      });
    }

    // api.enumService
    //   .getValues({ typeName: "ActivityApplyStatus" })
    //   .then((res: any) => {
    //     this.applyStatusList = res;
    //   });

    api.activityApply
      .getTotalDetails({
        activityId: this.activityId ? this.activityId : undefined,
      })
      .then((res) => {
        this.totalDetail = res;
      });
  }

  selectOption = "";

  applyStatusList: any[] = [
    { text: "已付款", value: "1" },
    { text: "未付款", value: "2" },
    { text: "已取消", value: "3" },
  ];

  selectChange(e: any) {
    switch (e) {
      case "1":
        this.queryForm.isPay = true;
        this.queryForm.isPriced = undefined;
        this.queryForm.status = undefined;
        this.queryForm.specialType = 1;
        break;
      case "2":
        this.queryForm.isPay = false;
        this.queryForm.isPriced = undefined;
        this.queryForm.status = undefined;
        this.queryForm.specialType = 2;
        break;
      case "3":
        this.queryForm.isPay = undefined;
        this.queryForm.isPriced = undefined;
        this.queryForm.status = ActivityApplyStatus.Cancel;
        this.queryForm.specialType = 3;
        break;
      default:
        this.queryForm.isPay = undefined;
        this.queryForm.isPriced = undefined;
        this.queryForm.status = undefined;
        this.queryForm.specialType = undefined;
    }
  }

  queryForm: ActivityApplyPagedResultRequestDto = {
    applyUserName: "",
    applyUserPhone: "",
    activityTitle: "",
    isPay: undefined,
    isPriced: undefined,
    status: undefined,
    specialType: undefined,
    activityId: undefined,
  };

  detail: ActivityDto = {};

  totalDetail: any = {};

  activityId = 0;
  id = 0;

  // 获取表数据
  fetchData(params: any) {
    params = Object.assign({}, params);
    console.log("test:", params);
    return api.activityApply.getAllPriced(params);
  }

  // @Watch("$route.params")
  // changeRoute(newVal: any) {
  //   this.pagedTableView!.fetchData();
  // }

  // 编辑
  handleDetail(index: number, row: ActivityApplyDto) {
    this.detailId = row.id!;
    return this.$router.push({
      name: "ActivityApplyDetail",
      params: { id: row.id! + "" },
    });
  }

  routerActivity(index: number, row: ActivityApplyDto) {
    this.queryForm.activityId = row.activityId;
    this.queryForm.activityTitle = row.activity!.title;
    this.pagedTableView!.fetchData();
    api.activityApply
      .getTotalDetails({
        activityId: this.queryForm.activityId,
      })
      .then((res) => {
        this.totalDetail = res;
      });
    // this.$router.push({
    //   query: merge(this.$route.query, { id: this.queryForm.activityId }),
    // });
  }

  // 删除
  // async handleDelete(index: number, row: ActivityApplyDto) {
  //   this.$confirm("你确定删除吗?", "提示").then(async () => {
  //     await api.activity
  //       .delete({
  //         id: row.id,
  //       })
  //       .then((res) => {
  //         this.$message({
  //           type: "success",
  //           message: "删除成功!",
  //         });
  //       });
  //   });
  // }

  handelOnSaved() {
    this.fetchData(this.queryForm);
  }
}
